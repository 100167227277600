import {EarlyAccessFeatureStatus} from 'app/earlyAccess/EarlyAccessFeatureStatus';

const DEFAULT_FALLBACK_URL = '/select';
const COMPETITIONS_FALLBACK_URL = '/competitions';

type RootUrl = `/region/${string}` | null;
type TestUrl = `/test_region/${string}` | null;
export type MainUrlTemplate = `/region/${string}` | `/test_region/${string}` | typeof DEFAULT_FALLBACK_URL;
export type MatchesUrlTemplate = `/region/${string}/matches` | typeof DEFAULT_FALLBACK_URL;
export type MatchesLiveUrlTemplate = `/region/${string}/matches/live` | typeof DEFAULT_FALLBACK_URL;
export type MatchesCancelledUrlTemplate = `/region/${string}/matches/cancelled` | typeof DEFAULT_FALLBACK_URL;
export type CompetitionsUrlTemplate = `/region/${string}/competitions` | typeof COMPETITIONS_FALLBACK_URL;
export type MarketplaceUrlTemplate = `/region/${string}/marketplace` | typeof DEFAULT_FALLBACK_URL;
export type TransfersUrlTemplate = `/region/${string}/transfers` | typeof DEFAULT_FALLBACK_URL;

export default class District {
  private readonly slug: string | null;
  private readonly regionIndexV2Status?: EarlyAccessFeatureStatus;

  constructor(slug: string | null, regionIndexV2Status: EarlyAccessFeatureStatus = EarlyAccessFeatureStatus.ENABLED) {
    this.slug = slug;
    this.regionIndexV2Status = regionIndexV2Status;
  }

  private getRootUrl = (): RootUrl => {
    if (!this.slug) {
      return null;
    }
    return `/region/${this.slug}`;
  };

  private getTestUrl = (): TestUrl => {
    if (!this.slug) {
      return null;
    }
    return `/test_region/${this.slug}`;
  };

  getMainUrl = (): MainUrlTemplate => {
    const url = this.isRegionIndexV2() ? this.getRootUrl() : this.getTestUrl();
    return url ?? DEFAULT_FALLBACK_URL;
  };

  getMatchesUrl(): MatchesUrlTemplate {
    const rootUrl = this.getRootUrl();
    return rootUrl ? `${rootUrl}/matches` : DEFAULT_FALLBACK_URL;
  }

  getMatchesLiveUrl(): MatchesLiveUrlTemplate {
    const rootUrl = this.getRootUrl();
    return rootUrl ? `${rootUrl}/matches/live` : DEFAULT_FALLBACK_URL;
  }

  getMatchesCancelledUrl(): MatchesCancelledUrlTemplate {
    const rootUrl = this.getRootUrl();
    return rootUrl ? `${rootUrl}/matches/cancelled` : DEFAULT_FALLBACK_URL;
  }

  getCompetitionsUrl(): CompetitionsUrlTemplate {
    const rootUrl = this.getRootUrl();
    return rootUrl ? `${rootUrl}/competitions` : COMPETITIONS_FALLBACK_URL;
  }

  getMarketplaceUrl(): MarketplaceUrlTemplate {
    const rootUrl = this.getRootUrl();
    return rootUrl ? `${rootUrl}/marketplace` : DEFAULT_FALLBACK_URL;
  }

  getTransfersUrl(): TransfersUrlTemplate {
    const rootUrl = this.getRootUrl();
    return rootUrl ? `${rootUrl}/transfers` : DEFAULT_FALLBACK_URL;
  }

  private isRegionIndexV2(): boolean {
    return this.regionIndexV2Status === EarlyAccessFeatureStatus.ENABLED;
  }
}
