import {EarlyAccessFeatureStatus} from 'app/earlyAccess/EarlyAccessFeatureStatus';

export interface featureFlag {
  name: string;
  slug: string;
  availableUntil: Date | number;
  defaultValue?: EarlyAccessFeatureStatus;
}

const PREFIX = 'feat_';

export const ExpFeatDistrictMainPage: featureFlag = {
  name: 'Neues Design der Startseite',
  slug: `${PREFIX}district_main_page`,
  availableUntil: 7,
  defaultValue: EarlyAccessFeatureStatus.ENABLED,
};

const featureFlags: featureFlag[] = [ExpFeatDistrictMainPage];

export {featureFlags};
