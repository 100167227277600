import {featureFlags} from 'app/featureFlags/featureFlags';
const createInitStateScheme = (): Record<string, string> => {
  let state = {};
  featureFlags.forEach(flag => {
    state[flag.slug] = flag.defaultValue ?? '';
  });

  return state;
};

const initialData = createInitStateScheme();
export type EarlyAccessAction = {type: 'EARLY_ACCESS_FEATURE_UPDATE'; data: Record<string, string>};

const EarlyAccessReducer = (state = initialData, action: EarlyAccessAction) => {
  switch (action.type) {
    case 'EARLY_ACCESS_FEATURE_UPDATE':
      return {...state, ...action.data};
    default:
      return state;
  }
};

const createInitialEarlyAccessData = () => {
  const copyInitialState = JSON.parse(JSON.stringify(initialData));
  return {...copyInitialState};
};
export {EarlyAccessReducer, createInitialEarlyAccessData};
