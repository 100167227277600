const news = {
  value: 'News',
  slug: ['news'],
  param: 'news',
};

const matchevent = {
  value: 'Liveticker',
  slug: ['matchevent'],
  param: 'matchevent',
};

const gallery = {
  value: 'Galerie',
  slug: ['galerie'],
  param: 'gallery',
};

const transfer = {
  value: 'Transfer',
  slug: ['transfer'],
  param: 'transfer',
};

const matchdelay = {
  value: 'Spielabsage/-verlegung',
  slug: ['spielabsage', 'spielverlegung'],
  param: 'matchdelay',
};

const finalScore = {
  value: 'Endstand',
  slug: ['endstand'],
  param: 'final-score',
};

const elevenOfTheWeek = {
  value: 'Elf der Woche',
  slug: ['elf-der-woche'],
  param: 'topeleven',
};

const coachChange = {
  value: 'Trainerwechsel',
  slug: ['neuer-trainer', 'trainer-gestrichen'],
  param: 'coach-change',
};

const injury = {
  value: 'Verletzung',
  slug: ['verletzung'],
  param: 'injury',
};

const playlist = {
  value: 'Video',
  slug: ['fupa-tv'],
  param: 'fupa-tv',
};

const video = {
  value: 'Video',
  slug: ['video'],
  param: 'video',
};

const ban = {
  value: 'Sperre',
  slug: ['sperre'],
  param: 'ban',
};

const scorer = {
  value: 'Torjäger',
  slug: ['competition-scorers'],
  param: 'scorer',
};

const matchday = {
  value: 'Spieltag',
  slug: ['matchday'],
  param: 'matchday',
};

const leagueFilters = [
  matchevent,
  news,
  gallery,
  transfer,
  matchdelay,
  finalScore,
  elevenOfTheWeek,
  coachChange,
  injury,
  playlist,
  ban,
  scorer,
  matchday,
];

const districtFilters = [gallery, playlist, matchday];
const districtTransferFilters = [transfer, coachChange];

const cupFilters = [matchevent, news, gallery, matchdelay, playlist, scorer, matchday];

const clubFilters = [news, gallery, playlist, matchday, transfer, coachChange, finalScore, matchdelay];

const playerFilters = [matchevent, news, transfer, elevenOfTheWeek, injury, video, ban];

const teamFilters = [matchevent, news, gallery, matchdelay, finalScore, coachChange, injury, playlist, ban, matchday];

const teamTransferFilters = [transfer];

const marketplaceFilters = [
  {
    value: 'Trainer gesucht',
    slug: ['searchingcoach'],
    param: 'searchingcoach',
  },
  {
    value: 'Spieler gesucht',
    slug: ['searchingplayer'],
    param: 'searchingplayer',
  },
  {
    value: 'Verein gesucht',
    slug: ['searchingclub'],
    param: 'searchingclub',
  },
  {
    value: 'Trainer sucht',
    slug: ['coachsearching'],
    param: 'coachsearching',
  },
  {
    value: 'Spieler sucht',
    slug: ['playersearching'],
    param: 'playersearching',
  },
  {
    value: 'Verein sucht',
    slug: ['clubsearching'],
    param: 'clubsearching',
  },
  {
    value: 'Testspielgegner gesucht',
    slug: ['searchingopponent'],
    param: 'searchingopponent',
  },
  {
    value: 'Sonstiges',
    slug: ['other'],
    param: 'other',
  },
];

const matchFilters = [
  {value: 'Live', slug: ['live'], param: 'live'},
  {value: 'Abgesagt', slug: ['cancelled'], param: 'cancelled'},
];

export {
  leagueFilters,
  districtFilters,
  districtTransferFilters,
  cupFilters,
  clubFilters,
  playerFilters,
  teamFilters,
  teamTransferFilters,
  marketplaceFilters,
  matchFilters,
};
